<template>
  <div>
    <div class="mb-4">
      <v-btn :to="{name: 'roleList'}" text style="color: #ff9800">
        <v-icon>mdi-arrow-left</v-icon>
        <span> Back</span>
      </v-btn>
    </div>
    <v-card>
      <v-card-title>{{ dataItem.keyword }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{{ dataItem.id }}</td>
              </tr>
              <tr>
                <td>Keyword</td>
                <td>{{ dataItem.keyword }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ dataItem.name }}</td>
              </tr>
              <tr>
                <td>Created date</td>
                <td>{{ dataItem.createdDate | dateTimeFormat }}</td>
              </tr>
              <tr>
                <td>Updated date</td>
                <td>{{ dataItem.updatedDate | dateTimeFormat }}</td>
              </tr>
              <tr>
                <td>permission id </td>
                <td>{{ dataItem.permissionIds }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "RoleView",
  beforeRouteEnter(to, from, next) {
    if ('id' in to.params) {
      next()
    } else {
      next({name: 'roleList'})
    }
  },
  data() {
    return {
      dataItem: {}
    }
  },
  created() {
    let id = this.$route.params.id
    this.$http.get(`Role/getRole?roleId=${id}`).then((res)=>{
      if (res && res.result) {
        // console.log(res.result)
        this.dataItem = res.result
      } else {
        this.$router.push({name: 'roleList'})
      }
    }).catch(e => {
      console.log(e)
      this.$router.push({name: 'roleList'})
    })
  }
}
</script>

<style scoped>

</style>
